<template>
  <div class="conectividadPublica">
        <!-- CONTENIDO -->
    <div class="container">
         <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
     <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Programa de Conectividad en Sitios Públicos 2022</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom:30px;">Programa de Conectividad en Sitios Públicos 2022</h1>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <p class="text-justify">La Secretaría de Infraestructura, Comunicaciones y Transportes (SICT) es la encargada de emitir anualmente los Programas de Cobertura Social y el de Conectividad en Sitios Públicos, de acuerdo con lo establecido en el Título Décimo, Capítulo Único, Artículos 210 y 211 de la Ley Federal de Telecomunicaciones y Radiodifusión (LFTR).</p>
		  <p class="text-justify">La estrategia de conectividad definida por el Programa de Conectividad en Sitios Públicos (PCSP 2022) se sustenta en las disposiciones del artículo 6&#176; de la Constitución Política de los Estados Unidos Mexicanos, así como en los conceptos de servicios públicos de interés general, cobertura universal y acceso a los derechos sociales, retomados en la Ley General de Desarrollo Social y en la LFTR. </p>
		  
		  <p class="text-justify">El <strong>PCSP 2022</strong> es el instrumento de política pública que hace visible, a través del Repositorio único, la demanda de <strong>24,814</strong> sitios públicos prioritarios por conectar ubicados en las Localidades de Atención Prioritaria de Cobertura Social para garantizar el servicio de Internet gratuito y con ello contribuir al ejercicio del derecho humano consagrado en el artículo 6&#176; Constitucional.</p>
		  
		  <p class="text-justify">El objetivo del PCSP 2022 es: <strong>Contribuir al logro de la cobertura universal a través de la integración de un Repositorio Único de Sitios Públicos por conectar asociados a programas y proyectos públicos y con necesidades de conectividad a Internet gratuito, principalmente en aquellos inmuebles ubicados dentro de las Localidades de Atención Prioritaria de Cobertura Social.</strong><sup>1</sup></p>
		  
		  <p class="text-justify">En este sentido, el PCSP 2022 es la referencia que todos los operadores de servicios de Internet en México pueden considerar para el diseño y desarrollo de sus planes de conexión en sitios públicos, especialmente la empresa CFE Telecomunicaciones e Internet para Todos.</p>
		  
		  <hr>
		  
		  <p class="text-justify"><sup>1</sup> Identificadas por el Programa de Cobertura Social 2021-2022.</p>
		  
		  
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/Programa_de_Conectividad_en_Sitios_Publicos_2022.pdf" download="Conectividad.pdf">Descargar archivo PDF</a>
		  
		  
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/sitios_publicos_2022.ods" download="sitios_publicos.ods">CONSULTAR DATOS ABIERTOS</a>
        </div>
      </div>
     
       <div class="row">
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href="/conectividad-sitios-publicosInter">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'conectividadPublica',
  data(){
    return{
      pdfData:''
    }
  },
 mounted(){
   
 },
  methods: {
    

  },
}
</script>

